<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-vuetify"
      inline
      class="px-5 py-3"
      title="Дебиторка"
    >
      <v-row>
        <v-col cols="12">
          <v-filter
            :filters="filters"
            :filter-items="filterItems"
            @filterData="filterData"
            @setFilter="resetPage"
          ></v-filter>
        </v-col>
      </v-row>
<!--      <v-row>-->
<!--        <v-col cols="12" lg="6" class="d-flex flex-row">-->
<!--          <v-file-input-->
<!--            v-model="file"-->
<!--            label="Загрузка файлов"-->
<!--            small-chips-->
<!--            dense-->
<!--          />-->
<!--          <v-btn-->
<!--            outlined-->
<!--            small-->
<!--            color="green darken-1"-->
<!--            @click="uploadFile"-->
<!--            :disabled="!file"-->
<!--          >Загрузить</v-btn>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="total"
        :footer-props="footerProps"
        item-key="id"
        dense
      >
        <template v-slot:item.dt="{ item }">
          {{ item.dt | moment('timezone', 'Asia/Yekaterinburg', 'L') }}
        </template>
        <template v-slot:item.place_type_id="{ item }">
          {{ getType(item.place_type_id) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn icon @click="openDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn icon @click="openHistory(item)"><v-icon>mdi-history</v-icon></v-btn>
        </template>
      </v-data-table>
    </base-card>
    <v-dialog v-model="dialog" persistent max-width="520px">
      <v-card>
        <v-card-title class="headline">Комментарий</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-textarea
            v-model="comment"
            outlined
            label="Комментарий"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="closeDialog">Отмена</v-btn>
          <v-btn color="green darken-1" text @click="save">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHistory" persistent max-width="700px">
      <v-card>
        <v-card-title class="headline">История</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headersHistory"
            :items="itemsHistory"
            :footer-props="footerProps"
            item-key="id"
            dense
          >
            <template v-slot:item.dt="{ item }">
              {{ item.dt | moment('timezone', 'Asia/Yekaterinburg', 'L') }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="closeHistory">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import pagination from '../../mixins/pagination'
import VFilter from '../../components/VFilter'

export default {
  name: 'DebitList',
  components: { VFilter },
  mixins: [pagination],
  data: () => ({
    headers: [
      { text: 'Дата', value: 'dt', divider: true, width: '100px' },
      { text: 'ЛС', value: 'contract' },
      { text: 'ФИО', value: 'fio' },
      { text: 'Объект', value: 'place' },
      { text: 'Адрес', value: 'address' },
      { text: 'Тип', value: 'place_type_id' },
      { text: 'Площадь', value: 'total_area_size' },
      { text: 'Телефон', value: 'phone' },
      { text: 'Баланс', value: 'balance' },
      { text: 'Оплата', value: 'payment' },
      { text: 'Комментарий', value: 'comment', divider: true },
      { value: 'action', width: '110px' }
    ],
    headersHistory: [
      { text: 'Дата', value: 'dt', divider: true, width: '100px' },
      { text: 'Баланс', value: 'balance' },
      { text: 'Оплата', value: 'payment' }
    ],
    itemsHistory: [],
    dialogHistory: false,
    dialog: false,
    comment: null,
    item: null,
    type: [
      { value: 1, text: 'Квартира' },
      { value: 2, text: 'Машиноместо' },
      { value: 3, text: 'Кладовая' },
      { value: 4, text: 'Апартаменты' },
      { value: 5, text: 'Нежилое помещение' },
      { value: 6, text: 'Дом/Коттедж' },
      { value: 7, text: 'Таунхаус' },
      { value: 8, text: 'Дуплекс' },
      { value: 9, text: 'Комната' }
    ],
    file: null
  }),
  async created () {
    await this.getData()
  },
  computed: {
    pagination: {
      get: function () {
        return this.$store.state.debit.pagination
      },
      set: function (value) {
        this.$store.commit('debit/SET_PAGINATION', value)
      }
    },
    filterItems: function () {
      return this.$store.state.debit.filterItems
    },
    filters: {
      get: function () {
        return this.$store.state.debit.filters
      },
      set: function (value) {
        this.$store.commit('debit/SET_FILTERS', value)
      }
    }
  },
  methods: {
    async getData () {
      await this.$store.dispatch('debit/GET_ITEMS')
      this.filterData()
    },
    filterData () {
      this.setParams()
      this.$store.dispatch('debit/GET_DEBIT', this.getParams).then(response => {
        this.items = response.debit
        this.meta = response.meta
      })
    },
    openDialog (item) {
      this.item = item
      this.comment = item.comment
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
      this.comment = null
    },
    save () {
      this.item.comment = this.comment
      const payload = {
        id: this.item.id,
        comment: this.comment
      }
      this.closeDialog()
      this.$store.dispatch('debit/POST_DEBIT_COMMENT', payload)
    },
    getType (val) {
      return this.type.find(type => type.value === val).text
    },
    openHistory (item) {
      this.$store.dispatch('debit/GET_DEBIT_HISTORY', { contract: item.contract }).then(response => {
        this.itemsHistory = response.debit
        this.dialogHistory = true
      })
    },
    closeHistory () {
      this.dialogHistory = false
    },
    uploadFile () {
      const formData = new FormData()
      this.$store.commit('OVERLAY_ON')
      formData.append('file', this.file)
      this.$store.dispatch('debit/POST_UPLOAD_FILE', { formData: formData }).then(response => {
        console.log(response)
      }).catch(error => {
        console.log(error.response.data.message || '')
      }).finally(() => {
        this.file = null
        this.$store.commit('OVERLAY_OFF')
      })
    }
  }
}
</script>

<style scoped>

</style>
