<template>
  <v-container fluid>
    <base-card
      color="indigo"
      icon="mdi-briefcase"
      inline
      class="px-5 py-3"
      title="Телефонный справочник"
    >
      <v-row>
        <v-col cols="12" class="d-flex row justify-end">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-8"
            label="Поиск"
            hide-details
            single-line
            style="max-width: 250px;"
            clearable
          />
        </v-col>
      </v-row>

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="items"
        :search.sync="search"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="footerProps"
        :options.sync="pagination"
      >
        <template v-slot:item.phone="{ item }">
          <span
            v-for="(i, key) in item.phone"
            :key="key"
          >
            {{ i | VMask('+7 (###) ###-####') }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            text icon color="accent" dark class="mr-2" @click.stop="edit(item)"
            v-tooltip.bottom="`Редактировать запись`"
          ><v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            text icon color="accent" dark class="mr-2" @click.stop="delete_record(item.guid)"
            v-tooltip.bottom="`Удалить запись`"
          ><v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </base-card>

    <v-dialog v-model="dialog" persistent max-width="620px">
      <v-card>
        <v-card-title>{{ headerDialog }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="dialogData.facility_id"
                :items="facility"
                item-text="name"
                item-value="id"
                label="Объект"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="dialogData.name"
                label="Название"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-masked-input
                v-for="i in phone_count"
                :key="i"
                v-model="dialogData.phone[i-1]"
                :label="`Телефон #${i}`"
                :rules="[v => !!v || 'Обязательное поле']"
                :mask="'+7 (###) ###-####'"
              >
                <template v-slot:prepend>{{ `${i}.` }}</template>
                <template v-slot:append-outer>
                  <v-btn icon color="primary" @click="addPhone">
                    <v-icon>mdi-plus-thick</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="removePhone(i-1)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </template>
              </v-masked-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="primary mr-2" small @click.stop="dialogCancel">Отмена</v-btn>
          <v-btn class="success" small @click.stop="dialogSave">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import pagination from '../mixins/pagination'
import VMaskedInput from '../components/VMaskedInput'
export default {
  name: 'PhoneBook',
  components: { VMaskedInput },
  mixins: [pagination],
  data: () => ({
    sortBy: 'full_name',
    sortDesc: false,
    inactive: false,
    headers: [
      { text: 'Название', value: 'name' },
      { text: 'Объект', value: 'facility.name' },
      { text: 'Телефон', value: 'phone', width: '150px' },
      { sortable: false, value: 'actions' }
    ],
    facility: [],
    headerDialog: '',
    dialogData: {
      guid: null,
      facility_id: undefined,
      name: null,
      phone: []
    }
  }),
  created () {
    this.$eventHub.$on('refresh', this.refresh)
    this.$eventHub.$on('add', this.add)
    this.$eventHub.$on('back', this.goBack)
    this.getData()
  },
  destroyed () {
    this.$eventHub.$off('refresh', this.refresh)
    this.$eventHub.$off('add', this.add)
    this.$eventHub.$off('back', this.goBack)
  },
  computed: {
    search: {
      get: function () {
        return this.$store.state.phoneBook.search
      },
      set: function (value) {
        this.$store.state.phoneBook.search = value
      }
    },
    pagination: {
      get: function () {
        return this.$store.state.phoneBook.pagination
      },
      set: function (value) {
        this.$store.commit('phoneBook/SET_PAGINATION', value)
      }
    },
    phone_count: function () {
      return (this.dialogData.phone && this.dialogData.phone.length > 1) ? this.dialogData.phone.length : 1
    },
    dialog: function () {
      return this.$store.state.phoneBook.dialogPhoneBook
    }
  },
  methods: {
    getData () {
      this.filterData()
      this.$store.dispatch('facility/GET_FACILITY').then(response => {
        this.facility = response
      })
    },
    filterData () {
      this.$store.dispatch('phoneBook/GET_PHONE_BOOK', this.getParams).then(response => {
        this.items = response.phoneBook
        this.meta = response.meta
      })
    },
    dialogSave () {
      this.$store.dispatch('phoneBook/POST_PHONE_BOOK', this.dialogData).then(() => {
        this.filterData()
        this.dialogCancel()
      })
    },
    dialogCancel () {
      this.$store.commit('phoneBook/CLOSE_DIALOG_PHONE_BOOK')
    },
    addPhone () {
      this.dialogData.phone.push('')
    },
    removePhone (val) {
      if (this.phone_count > 1) this.dialogData.phone.splice(val, 1)
    },
    add () {
      this.headerDialog = 'Добавить запись'
      this.dialogData = { guid: null, facility_id: undefined, name: null, phone: [] }
      this.$store.commit('phoneBook/OPEN_DIALOG_PHONE_BOOK')
    },
    edit (value) {
      this.headerDialog = 'Редактировать запись'
      this.dialogData = { guid: value.guid, facility_id: value.facility?.id || null, name: value.name, phone: [...value.phone] }
      this.$store.commit('phoneBook/OPEN_DIALOG_PHONE_BOOK')
    },
    delete_record (guid) {
      this.$store.dispatch('phoneBook/DELETE_PHONE_BOOK', { guid: guid }).then(() => {
        this.filterData()
      })
    },
    refresh () {
      this.filterData()
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
